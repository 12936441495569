import type { Feature } from '@/types';
import type { IconComponent } from '@/types/showDetails';

import feature1Image from '@/images/features/feature1.png';
import feature2Image from '@/images/features/feature2.png';
import feature3Image from '@/images/features/feature3.png';
import feature0Image from '@/images/features/feature0.png';
import fullDiagram from '@/images/features/pro-pipeline-animation.mp4';
import partialIngestion from '@/images/features/partial-value-diagram-ingestion-bg-grid.png';
import partialProcessing from '@/images/features/partial-value-diagram-processing-bg-grid.png';
import partialDistribution from '@/images/features/partial-value-diagram-distribution-bg-grid.png';

import {
  IconBrandX,
  IconBrandTwitterFilled,
  IconBrandInstagram,
  IconBrandYoutubeFilled,
  IconBrandFacebookFilled,
  IconBrandPaypalFilled,
  IconBrandPatreonFilled,
  IconBrandLinkedin,
  IconBrandTiktokFilled,
} from '@tabler/icons-react';

import { SubstackIcon, MediumIcon } from '@/icons/social-media-icons';

import { BuyMeACoffeeIcon, KoFiIcon } from '@/icons/crowdfunding-icons';

export const FEATURES = [
  {
    id: 1,
    title: 'A holistic podcast marketing engine',
    text1:
      'Your always-on complete podcast marketing companion - from transcripts to social content, all working together seamlessly.',
    text2:
      "Save time and expand reach while maintaining full control of your show's presence across all channels. Win-win!",
    image: fullDiagram,
    isVideo: true,
  },
  {
    id: 2,
    title: 'Simple feed indexing & automated ingestion',
    text1: "Find your show or enter your RSS feed, that's all it takes to start. No code necessary.",
    text2: 'We constantly monitor your show for new episodes or other changes. No manual actions.',
    image: partialIngestion,
  },
  {
    id: 3,
    title: 'Top quality transcriptions & AI-derived insights',
    text1:
      'Our AI engine transforms your episodes into marketing-ready assets, from speaker-labeled transcripts to social-ready quotes.',
    text2:
      'Get episode summaries, key takeaways, top quotes, and shareable moments - all automatically generated and ready to use anywhere.',
    image: partialProcessing,
  },
  {
    id: 4,
    title: 'Easy-breezy marketing & distribution',
    text1: 'Share your content everywhere with click-to-copy access to episode insights, quotes, and promotional copy.',
    text2:
      'Boost discovery across search & podcast platforms with SEO-optimized descriptions and easy social sharing tools.',
    image: partialDistribution,
  },
  {
    id: 5,
    title: 'Automagically up your marketing game',
    text1: 'If you build it, they will come if...',
    text2:
      'Transcripts plus marketing copy for your website and social channels, as well as search engine optimization input for all podcatchers—all automagically created for you for free!',
    image: feature1Image,
  },
  {
    id: 6,
    title: 'An amazing new discovery platform to find your show',
    text1:
      'All shows claimed on DeepCast Pro will be featured on the DeepCast discovery platform for listeners. This will immediately expand your audience reach and open new revenue opportunities.',
    image: feature0Image,
  },
  {
    id: 7,
    title: 'Help us help you',
    text1:
      "Elevate your podcast's presence by adding donation links, updating summaries, including your social handles and your profile info.",
    text2: 'This richer context for you and your show helps you transform listeners into true fans.',
    image: feature2Image,
  },
  {
    id: 8,
    title: 'This is just the beginning',
    text1:
      "Soon enough, we'll be offering 1-click merchandising, social content generation & calendar automation, AI-powered customer engagement tools, and more...",
    image: feature3Image,
  },
];

export const PLAN_FEATURES: Feature[] = [
  {
    name: 'Episode processing',
    category: 'Core',
    free: 'New episodes only',
    basic: 'New episodes and an additional 50 back catalog episodes annually',
    isShowcased: true,
  },
  {
    name: 'Additional episode processing',
    category: 'Core',
    free: false,
    basic: '$10 per 100 back catalog episodes',
    isShowcased: true,
  },
  {
    name: 'Automated transcription',
    category: 'Core',
    free: true,
    basic: true,
    isShowcased: true,
  },
  {
    name: 'Speaker diarization',
    category: 'Core',
    free: true,
    basic: true,
    isShowcased: true,
  },
  {
    name: 'Speaker label editing',
    category: 'Core',
    free: true,
    basic: true,
    isShowcased: true,
  },
  {
    name: 'Multiple transcript formats (diarize, VTT, SRT)',
    category: 'Core',
    free: true,
    basic: true,
    isShowcased: true,
  },
  {
    name: 'Episode summaries (3 variants)',
    category: 'Content Generation',
    free: true,
    basic: true,
  },
  {
    name: 'Key takeaways',
    category: 'Content Generation',
    free: true,
    basic: true,
  },
  {
    name: 'Key quotes with attribution',
    category: 'Content Generation',
    free: true,
    basic: true,
  },
  {
    name: 'Topics & entity detection',
    category: 'Content Generation',
    free: true,
    basic: true,
  },
  {
    name: 'Automatic chapter generation with insights',
    category: 'Content Generation',
    free: true,
    basic: true,
    isShowcased: true,
  },
  {
    name: 'Asset download (zip)',
    category: 'Distribution & Export',
    free: true,
    basic: true,
  },
  {
    name: 'Instant-copy directly',
    category: 'Distribution & Export',
    free: true,
    basic: true,
  },
  {
    name: 'Marketing assets',
    category: 'Distribution & Export',
    free: true,
    basic: true,
  },
  {
    name: 'Multiple show support',
    category: 'Podcast Management',
    free: false,
    basic: true,
  },
  {
    name: 'Show information editing',
    category: 'Podcast Management',
    free: true,
    basic: true,
  },
  {
    name: 'Social media handles',
    category: 'External Links',
    free: true,
    basic: true,
  },
  {
    name: 'Funding links integration',
    category: 'External Links',
    free: true,
    basic: true,
  },
  {
    name: 'Website links (incl. affiliate)',
    category: 'External Links',
    free: true,
    basic: true,
  },
  {
    name: 'Pre-release transcript & insights processing',
    category: 'Coming Soon',
    free: false,
    basic: true,
  },
  {
    name: 'Automated social media images',
    category: 'Coming Soon',
    free: false,
    basic: true,
  },
  {
    name: 'Automated audiogram generation',
    category: 'Coming Soon',
    free: false,
    basic: true,
  },
];

export const SOCIAL_ICONS: Record<string, IconComponent> = {
  x: IconBrandX,
  twitter: IconBrandTwitterFilled,
  instagram: IconBrandInstagram,
  youtube: IconBrandYoutubeFilled,
  facebook: IconBrandFacebookFilled,
  linkedin: IconBrandLinkedin,
  tiktok: IconBrandTiktokFilled,
  substack: SubstackIcon,
  medium: MediumIcon,
};

export const SOCIAL_NETWORK_OPTIONS = [
  { value: 'x', label: 'x.com/' },
  { value: 'twitter', label: 'twitter.com/' },
  { value: 'instagram', label: 'instagram.com/' },
  { value: 'youtube', label: 'youtube.com/' },
  { value: 'facebook', label: 'facebook.com/' },
  { value: 'linkedin', label: 'linkedin.com/in/' },
  { value: 'tiktok', label: 'tiktok.com/@' },
  { value: 'substack', label: 'substack.com/@' },
  { value: 'medium', label: 'medium.com/@' },
];

export const CROWDFUNDING_ICONS: Record<string, IconComponent> = {
  paypal: IconBrandPaypalFilled,
  patreon: IconBrandPatreonFilled,
  buymeacoffee: BuyMeACoffeeIcon,
  'ko-fi': KoFiIcon,
};

export const CROWDFUNDING_PLATFORMS_OPTIONS = [
  { value: 'paypal', label: 'PayPal' },
  { value: 'patreon', label: 'Patreon' },
  { value: 'buymeacoffee', label: 'Buy Me A Coffee' },
  { value: 'ko-fi', label: 'Ko-Fi' },
];

export const FAQ_LINK =
  'https://deepcast.notion.site/DeepCast-Pro-Frequently-Asked-Questions-2a569790c752416c8a57ffee3b8c393b';

export const ABOUT_LINK = 'https://deepcast.notion.site/About-DeepCast-5386453f0b144c1089f4bfe0447bba82';

export const CONTACT_LINK = 'https://deepcast.atlassian.net/servicedesk/customer/portal/3';

export const TERMS_OF_SERVICE_LINK =
  'https://deepcast.notion.site/DeepCast-Pro-Terms-of-Service-202aa48c52d14b61becc7df22a5c1761';

export const PRIVACY_POLICY_LINK =
  'https://deepcast.notion.site/DeepCast-Pro-Privacy-Policy-e2c11d100c444f94b426ec5cb3c53f0c';

export const HELP_CENTER_LINK =
  'https://deepcast.notion.site/DeepCast-Pro-Frequently-Asked-Questions-2a569790c752416c8a57ffee3b8c393b';

export const DEEPCAST_BADGES_LINK = 'https://deepcast.notion.site/DeepCast-Badges-b6bd91f47bc445028b4ddb624ba22465';

export const FOOTER_ITEMS = [
  {
    title: 'Help Center',
    link: '/help-center',
  },
  {
    title: 'Contact Us',
    link: '/contact',
  },
  {
    title: 'About DeepCast',
    link: '/about',
  },
  {
    title: 'DeepCast Badges',
    link: '/badges',
  },
  {
    title: 'Terms of Service',
    link: '/terms-of-service',
  },
  {
    title: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/deepcast-pro/',
  },
  {
    title: 'X / Twitter',
    link: 'https://x.com/deepcastpro',
  },
];

export const WEBSITE_LINKS_OPTIONS = [
  { value: 'podcast', label: 'Podcast site' },
  { value: 'newsletter', label: 'Newsletter' },
  { value: 'company', label: 'Company' },
  { value: 'other', label: 'Other' },
];

export const AFFILIATE_CARDS = [
  {
    title: 'ElevenLabs: Cutting edge text-to-speech',
    description:
      'Create the most realistic speech with the ElevenLabs AI audio platform, converting transcripts to flawlessly delivered audio for podcasts, marketing snippets, and more.',
    imageUrl: '/images/eleven-labs-affiliate.png',
    ctaText: 'Try ElevenLabs Today',
    ctaUrl: 'https://elevenlabs.io',
  },
  {
    title: 'Descript: One tool to create any video you want',
    description:
      'Descript is the AI-powered, fully featured, end-to-end video editor that you already know how to use. Create podcasts, viral clips, demo videos, and more.',
    imageUrl: '/images/descript-editing-affiliate.png',
    ctaText: 'Try Descript Now',
    ctaUrl: 'https://get.descript.com/oi0l3zrlc8l9',
  },
  {
    title: 'Doola: Company formation made simple',
    description:
      'Start your U.S. business from anywhere in the world. From Formation to Bookkeeping and Taxes, we’ve got your back at every step.',
    imageUrl: '/images/doola-affiliate.png',
    ctaText: 'Start your company now',
    ctaUrl:
      'https://www.doola.com/?utm_source=partnerstack&utm_medium=affiliate&utm_campaign=lucasdickey6813&pscd=partnersps.doola.com&ps_partner_key=bHVjYXNkaWNrZXk2ODEz&ps_xid=QJxbWabnl9JLG0&gsxid=QJxbWabnl9JLG0&gspk=bHVjYXNkaWNrZXk2ODEz',
  },
  {
    title: 'fiverr. You really can do it all',
    description: 'Get it done by tomorrow. Hire a freelancer. fiverr.',
    imageUrl: '/images/fiverr-affiliate.jpg',
    ctaText: 'Hire a fiverr now',
    ctaUrl: 'https://fiverr.partnerlinks.io/29lx4c4cctik',
  },
];

export const NOTIFY_URL = 'https://deepcast.atlassian.net/servicedesk/customer/portal/3/group/4/create/22';

export const COMING_SOON_FEATURES = [
  {
    title: 'Advertising Intelligence',
  },
  {
    title: 'Merchandising & Fulfillment',
  },
  {
    title: 'Automated Social Media Posting',
  },
];

export enum USER_ROLES {
  PRO_PAID_MEMBER = 'pro-paid-member',
  STAFF = 'staff',
}
